<template>
  <v-main>
    <template v-if="viewDataIsLoaded">
      <v-app-bar
        fixed
        elevate-on-scroll
        dark
        color="primary"
      >
        <v-btn
          icon
          @click="goHome()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ saloon.sln_id == '' ? 'Ny salong' : 'Salong: ' + saloon.sln_str_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="saveSaloon"
          >
            Spara
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-container>
        <v-row class="pt-16">
          <v-col class="pt-4 pb-16">
            <v-form ref="formEdit">
              <!--name-->
              <v-text-field
                label="Namn"
                v-model="saloon.sln_str_name"
                outlined
                dense
                :rules="[formRules.required]"
                validate-on-blur
                maxlength="35"
              ></v-text-field>
              <div class="text-subtitle-1 font-weight-medium py-2">Begränsa bokningar utifrån antal:</div>
              <!--appointment limit-->
              <v-btn-toggle
                v-model="saloon.sln_enm_multiple_appointment_type"
                class="xDenseToggle mb-4"
                color="blue darken-4"
                mandatory
              >
                <v-btn
                  v-for="multipleAppointmentType in multipleAppointmentTypes"
                  :key="multipleAppointmentType.value"
                  :value="multipleAppointmentType.value"
                  text
                  small
                  :disabled="!saloon.isNew && saloon.sln_enm_multiple_appointment_type != multipleAppointmentType.value"
                >
                  {{ multipleAppointmentType.text }}
                </v-btn>
              </v-btn-toggle>
              <!--appointment limit-->
              <v-text-field
                v-model="saloon.sln_int_appointment_limit"
                label="Maxantal"
                outlined
                dense
                type="number"
                min="1"
                max="1000"
                class="mt-2"
                :rules="[formRules.required, formRules.positiveInteger]"
              ></v-text-field>
                <div class="pt-8" v-if="!saloon.isNew">
                  <v-btn
                    text
                    color="error"
                    outlined
                    @click="confirmDelete = true"
                  >
                    Ta bort salongen
                  </v-btn>
                </div>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--loader-->
    <v-overlay
      :value="loading"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
    <!--delete confirmation-->
    <v-bottom-sheet v-model="confirmDelete">
      <v-sheet
        class="text-center"
        height="160px"
      >
        <div class="py-4">
          Salongen kommer nu tas bort
        </div>
        <v-btn
          color="bad"
          dark
          @click="removeSaloon()"
        >
          Ta bort
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </v-main>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'
import { dateMixin } from '../mixins/date'
import { arrayMixin } from '../mixins/array'

export default {
  name: 'SaloonEdit',
  components: {
    'app-snack-bar': SnackBar,
  },
  mixins: [generalMixin, urlMixin, dateMixin, arrayMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    loading: false,
    viewDataIsLoaded: false,
    confirmDelete: false,
    saloon: {
      sln_enm_multiple_appointment_type: null,
    },
    multipleAppointmentTypes: [],
  }),
  computed: {
    ...mapGetters(['agent']),
  },
  watch: {
  },
  methods: {
    getData() {
      console.log('getData')
      if (this.loading) {
        return
      }
      this.loading = true
      let relUrl = '/admin/saloon-edit/' + this.agent + '/' + this.id
      const url = this.url_composeApiUrl(relUrl)
      axios.get(url)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
    onData(data) {
      this.saloon = data.saloon
      this.multipleAppointmentTypes = data.multipleAppointmentTypes
      //final
      this.viewDataIsLoaded = true
    },
    onSave() {
      this.goHome()
    },
    goHome() {
      //go to home with event tab active, like this
      this.$router.replace({ name: 'home', hash: '#saloons' })
    },
    removeSaloon() {
      this.confirmDelete = false
      const relUrl = '/admin/saloon/' + this.agent + '/' + this.id
      const url = this.url_composeApiUrl(relUrl)
      axios.delete(url)
        .then(response => {
          console.log('response', response)
          if (response.data.success) {
            //success
            this.onSave()
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
        })
    },
    saveSaloon() {
      if (this.loading) {
        return
      }
      const formIsValid = this.$refs.formEdit.validate()
      if (!formIsValid) {
        snackBarBus.$emit('message', 'Formuläret är inte komplett ifyllt')
        return
      }
      this.loading = true
      const postData = {
        agent: this.agent,
        saloon: this.saloon,
      }
      const config = {}
      let relUrl = '/admin/saloon'
      const url = this.url_composeApiUrl(relUrl)
      console.log('post', postData)
      axios.post(url, postData, config)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onSave()
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
  },
  created() {
    this.getData()
  }
}
</script>
